import service from "@/api/service";
import { SearchRequest } from "@/api/ticket-order/request";
import { SearchResponse } from "@/api/ticket-order/response";

/**
 * チケット受注管理一覧検索APIをコールします。
 *
 * @param searchRequest チケット受注管理店舗検索のリクエストボディ
 * @return SearchResponse
 */
export async function search(searchRequest: SearchRequest) {
  const response = await service.post("/search-ticket-order", searchRequest);
  return response.data as SearchResponse;
}
