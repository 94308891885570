import {
  getModule,
  Module,
  VuexModule,
  MutationAction
} from "vuex-module-decorators";
import store from "@/store";
import { SessionStorage } from "@/store/session-storage";
import { SearchResponse, SearchItem } from "@/api/ticket-order/response";
import { SearchRequest } from "@/api/ticket-order/request";
import * as TicketOrderAPI from "@/api/ticket-order";
import { isSuccess } from "@/api/response";

const MODULE_NAME = "ticket-order/search";

/**
 * メニューのデコレーター
 */
export class ListItem {
  constructor(private _item: SearchItem) {}
  get userId() {
    return this._item.userId;
  }
  get ticketId() {
    return this._item.ticketId;
  }
  get userName() {
    return this._item.userName;
  }
  get tel() {
    return this._item.tel;
  }
  get mail() {
    return this._item.mail;
  }
  get orderDate() {
    return ListItem.formatHyphenToSlash(this._item.orderDate);
  }
  get title() {
    return this._item.title;
  }
  get status() {
    switch (this._item.status) {
      case 1:
        return "利用可";
      case 2:
        return "利用停止";
      case 3:
        return "有効期限切れ";
      case 4:
        return "利用済";
      case 5:
        return "削除済";
      default:
        return "不明";
    }
  }
  get updateAt() {
    return ListItem.formatHyphenToSlash(this._item.updateAt);
  }
  private static formatHyphenToSlash(orig: string) {
    return orig.replace(/-/g, "/");
  }
}

/**
 * チケット受注管理一覧検索API（/search-ticket-order）のレスポンスを管理するストアモジュール
 */
@Module({ dynamic: true, store, name: MODULE_NAME, namespaced: true })
class Search extends VuexModule {
  // state
  searchRequest: SearchRequest = {} as SearchRequest;
  searchResponse: SearchResponse = {} as SearchResponse;

  // getters
  get isSuccess() {
    return isSuccess(this.searchResponse);
  }

  get getMessage() {
    return this.searchResponse.message;
  }

  get getItems() {
    if (this.searchResponse.results) {
      return (this.searchResponse.results.items || []).map(
        i => new ListItem(i)
      );
    } else {
      return [] as ListItem[];
    }
  }

  get getTotalCount() {
    if (this.searchResponse.results) {
      return this.searchResponse.results.totalCount || 0;
    } else {
      return 0;
    }
  }

  get getSearchRequest() {
    return this.searchRequest;
  }

  // MutationActions
  @MutationAction
  async search(searchRequest: SearchRequest) {
    const searchResponse = await TicketOrderAPI.search(searchRequest);
    SessionStorage.setObject(MODULE_NAME, searchRequest);
    return {
      searchRequest,
      searchResponse
    };
  }

  @MutationAction
  async restore(initial = {} as SearchRequest) {
    const searchRequest = SessionStorage.getObject(
      MODULE_NAME,
      initial
    ) as SearchRequest;
    return {
      searchRequest
    };
  }

  @MutationAction
  async clearResponse() {
    return {
      searchResponse: {} as SearchResponse
    };
  }
}

export default getModule(Search);
