import service from "@/api/service";
import { ExportRequest } from "@/api/ticket-order-csv/request";
import { ExportResponse } from "@/api/ticket-order-csv/response";

/**
 * チケット受注管理CSVダウンロードAPIをコールします。
 *
 * @param exportRequest チケット受注管理CSVダウンロードAPIのリクエストボディ
 * @return response
 */
export async function exportOne(exportRequest: ExportRequest) {
  const response = await service.postReceiveCSV(
    "/export-ticket-order-csv",
    exportRequest
  );
  return response as ExportResponse;
}
