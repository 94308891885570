import { Component, Prop, Vue } from "vue-property-decorator";
import UlContentHeader from "@/components/UlContentHeader.vue";
import UlBreadcrumbs from "@/components/UlBreadcrumbs.vue";
import UIDataTable from "@/components/UIDataTable.vue";
import UIDatePicker from "@/components/UIDatePicker.vue";
import {
  DEFAULT_TABLE_OPTIONS,
  SORT_ORDER_DESC,
  TableOptions
} from "@/api/request";
import { SearchRequest } from "@/api/ticket-order/request";
import { ExportRequest } from "@/api/ticket-order-csv/request";
import * as TicketOrderCsvAPI from "@/api/ticket-order-csv";
import { isSuccess } from "@/api/response";
import TicketOrderSearch from "@/store/ticket-order/search";
import Flash, { ErrorAlert } from "@/store/common/flash";
import Loading from "@/store/loading";
import { saveAs } from "file-saver";
import Encoding from "encoding-japanese";

@Component({
  components: { UlContentHeader, UlBreadcrumbs, UIDataTable, UIDatePicker }
})
export default class Index extends Vue {
  @Prop({ type: String, required: true })
  id!: string;

  // ------------
  // 固定値
  // ------------
  // タイトル
  headingMain = "チケット受注管理";
  headingSub = "Ticket order management";
  breadCrumbs = [
    {
      text: "店舗一覧",
      disabled: false,
      to: { name: "ticket-order-management" }
    },
    { text: "詳細情報", disabled: true }
  ];
  // テーブルヘッダ（UIDataTableコンポーネントに渡す）
  tableHeaders = [
    { text: "会員ID", value: "userId", width: "80" },
    { text: "チケットID", value: "ticketId", width: "80" },
    // 20201223 C57_レポート-チケット受注管理詳細情報一覧画面_No.55 presq ishida start
    { text: "購入者名", value: "userName", width: "120" },
    // 20201223 C57_レポート-チケット受注管理詳細情報一覧画面_No.55 presq ishida end
    { text: "電話番号", value: "tel", sortable: false, width: "120" },
    // 20201223 C57_レポート-チケット受注管理詳細情報一覧画面_No.55 presq ishida start
    { text: "メールアドレス", value: "mail", sortable: false, width: "150" },
    // 20201223 C57_レポート-チケット受注管理詳細情報一覧画面_No.55 presq ishida end
    { text: "購入日", value: "orderDate", width: "120" },
    { text: "チケットタイトル", value: "title", width: "200" },
    {
      text: "チケットステータス",
      value: "status",
      width: "100"
    },
    { text: "ステータス更新日", value: "updateAt", width: "120" }
  ];

  menuProps = {
    closeOnClick: false,
    closeOnContentClick: false,
    disableKeys: true,
    openOnClick: false,
    maxHeight: 304
  };

  // ------------
  // 変動値
  // ------------

  // 検索入力オプション
  inputOptions = {} as SearchRequest;

  // テーブル検索オプション（UIDataTableコンポーネントに渡す）
  tableOptions: TableOptions = {
    page: 1,
    displayCount: 20,
    sortKey: "userId",
    sortOrder: SORT_ORDER_DESC
  };

  isDialogShowing = false;

  // ------------

  get loading() {
    return Loading.isLoading;
  }

  /**
   * テーブルに表示するアイテムリスト（UIDataTableコンポーネントに渡す）
   */
  get tableItems() {
    return TicketOrderSearch.getItems;
  }

  /**
   * 総件数（UIDataTableコンポーネントに渡す）
   */
  get totalCount() {
    return TicketOrderSearch.getTotalCount;
  }

  get ticketStatuses() {
    return [
      {
        id: "1",
        name: "利用可"
      },
      {
        id: "2",
        name: "利用停止"
      },
      {
        id: "3",
        name: "有効期限切れ"
      },
      {
        id: "4",
        name: "利用済"
      }
    ];
  }

  get shopId() {
    return this.id;
  }

  get shopName() {
    if (this.$route.query.shopName) {
      return this.$route.query.shopName;
    } else {
      return "";
    }
  }

  /**
   * createdライフサイクルフック（UIDataTableコンポーネントに渡す）
   */
  async created() {
    await TicketOrderSearch.restore(this.inputOptions as SearchRequest);
    const request = TicketOrderSearch.getSearchRequest;
    // 検索入力オプション
    request.ticketId && (this.inputOptions.ticketId = request.ticketId);
    request.name && (this.inputOptions.name = request.name);
    request.tel && (this.inputOptions.tel = request.tel);
    request.mail && (this.inputOptions.mail = request.mail);
    request.status && (this.inputOptions.status = request.status);
    request.start && (this.inputOptions.start = request.start);
    request.end && (this.inputOptions.end = request.end);

    // 検索テーブルオプション
    request.page && (this.tableOptions = request);
    await this.search();
  }

  /**
   * beforeDestroyライフサイクルフック
   */
  async beforeDestroy() {
    await TicketOrderSearch.clearResponse();
  }

  /**
   * ページング変更コールバック
   *
   * @param tableOptions TableOptions
   */
  async tableChangeCallback(tableOptions: TableOptions) {
    // 子コンポーネントへpagingOptionsの変更が通知される
    this.tableOptions = tableOptions;
    if (this.tableOptions.sortKey == null) {
      this.tableOptions.sortKey = "userId";
    }
    await this.search();
  }

  /**
   * 検索ボタンがクリックされた際のコールバック
   */
  async searchClickCallback() {
    await Flash.clear();
    await this.search();
  }

  /**
   * 掲載開始日が変更された際のコールバック
   *
   * @param date 選択された日付
   */
  changeFromCallback(date: string | null) {
    this.inputOptions.start = date;
  }

  /**
   * 掲載終了日が変更された際のコールバック
   *
   * @param date 選択された日付
   */
  changeToCallback(date: string | null) {
    this.inputOptions.end = date;
  }

  /**
   * ダウンロードボタンが押下された際のコールバック
   */
  async downloadClickCallback() {
    this.isDialogShowing = false;
    await Flash.clear();
    await this.exportCsv();
  }

  /**
   * 検索処理
   */
  async search() {
    const request = this.createSearchRequest();
    await TicketOrderSearch.search(request);
    if (!TicketOrderSearch.isSuccess) {
      await Flash.setErrorNow({
        message: TicketOrderSearch.getMessage,
        showReloadButton: true
      } as ErrorAlert);
    }
  }

  async exportCsv() {
    const ticketOrderCsvExportRequest = await TicketOrderCsvAPI.exportOne(
      this.createExportRequest()
    );
    if (
      ticketOrderCsvExportRequest &&
      ticketOrderCsvExportRequest.statusCd !== 200
    ) {
      await Flash.setErrorNow({
        message: ticketOrderCsvExportRequest.message,
        showReloadButton: false
      } as ErrorAlert);
    }

    this.saveCSV(
      ticketOrderCsvExportRequest.results.csvString,
      ticketOrderCsvExportRequest.results.fileName
    );
  }

  /**
   * dataから検索用のリクエストを作成する
   */
  private createSearchRequest(): SearchRequest {
    const request = { ...this.tableOptions } as SearchRequest;
    request.id = this.shopId;
    if (this.inputOptions.ticketId) {
      request.ticketId = this.inputOptions.ticketId;
    } else {
      delete request.ticketId;
    }

    if (this.inputOptions.name) {
      request.name = this.inputOptions.name;
    } else {
      delete request.name;
    }

    if (this.inputOptions.tel) {
      request.tel = this.inputOptions.tel;
    } else {
      delete request.tel;
    }

    if (this.inputOptions.mail) {
      request.mail = this.inputOptions.mail;
    } else {
      delete request.mail;
    }

    if (this.inputOptions.status) {
      request.status = this.inputOptions.status;
    } else {
      delete request.status;
    }

    if (this.inputOptions.start) {
      request.start = this.inputOptions.start;
    } else {
      delete request.start;
    }

    if (this.inputOptions.end) {
      request.end = this.inputOptions.end;
    } else {
      delete request.end;
    }

    return request;
  }

  createExportRequest() {
    const request = this.createSearchRequest() as ExportRequest;
    request.sortKey = request.sortKey.replace(
      /([A-Z])/g,
      s => `_${s.charAt(0).toLowerCase()}`
    );
    delete request.page;
    delete request.displayCount;
    return request;
  }

  private saveCSV(csvString: string, fileName: string) {
    const csvStringArray = Encoding.stringToCode(csvString);
    const sJISArray = Encoding.convert(csvStringArray, "SJIS", "UNICODE");
    const uint8Array = new Uint8Array(sJISArray);
    const blob = new Blob([uint8Array], {
      type: "text/csv"
    });
    saveAs(blob, fileName);
  }
}
